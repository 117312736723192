const dev = {
  s3: {
    REGION: "us-east-1",
    BUCKET: "jv-notes-app-2-api-dev-attachmentsbucket-17l46egitl05t"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://802mf5tnm4.execute-api.us-east-1.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_1m7k8XBHa",
    APP_CLIENT_ID: "1v4stg4ksi6ifr3vdr3qtnbgo4",
    IDENTITY_POOL_ID: "us-east-1:6e93aeab-bdea-49ac-9cce-4192ce1b63d4"
  },
  STRIPE_KEY: "pk_test_fxWJVwKWws0lLBJQVx5G8BOI",
};

const prod = {
  s3: {
    REGION: "us-east-1",
    BUCKET: "jv-notes-app-2-api-prod-attachmentsbucket-bkqmmk1pxf5j"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://4v1xow2xl5.execute-api.us-east-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_aTVILloBO",
    APP_CLIENT_ID: "2kvnj914325skrfhq7ttgvobf4",
    IDENTITY_POOL_ID: "us-east-1:cae81879-ee88-4b96-b10a-84911c5369d7"
  },
  STRIPE_KEY: "pk_test_fxWJVwKWws0lLBJQVx5G8BOI",
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};